import {find} from 'lodash';

import {Component, EventEmitter, Input, Output} from '@angular/core';

import {IResponseBase} from '../../../models/survey-responses/IResponseBase';
import {IMultilingualDataOption} from '../../../models/survey/ISurveyQuestion';
import {OptionModeEnum} from '../../enum/OptionMode.enum';
import {OptionType} from '../../enum/OptionType.enum';

export interface ClickHandlerResponse {
    option: IMultilingualDataOption;
    mode: OptionModeEnum;
}

@Component({
    selector: 'surveys-element-options-block',
    template: `
        <div class="options">
            <div class="container" [attr.aria-labelledby]="labeledby">
                <div *ngFor="let option of options">
                    <r-button
                        [selected]="isOptionSelected(option.id)"
                        [classes]="['option', option.text[language].length < 15 ? 'center-text' : '']"
                        [selection]="selection"
                        [affirmative]="true"
                        [disabled]="isOptionDisabled(option.id)"
                        [e2e]="'multi-select-option-' + option.id"
                        (onClick)="execute(option)"
                    >
                        {{ option.text[language] }}
                    </r-button>

                    <input
                        *ngIf="option.type === 'other' && formControls[option.id]"
                        type="text"
                        class="input"
                        [formControl]="formControls[option.id]"
                    />
                </div>
            </div>
        </div>
    `,
    styleUrls: ['./element-options-block.component.scss']
})
export class ElementOptionsBlockComponent {
    @Input()
    options: Array<IMultilingualDataOption>;

    @Input()
    responses: Array<IResponseBase>;

    @Input()
    selectedOptionId?: string;

    @Input()
    language: string;

    @Input()
    selection? = false;

    @Input()
    maxSelectionReached? = false;

    @Input()
    cssClasses?: string[];

    @Input()
    labeledby: string;

    @Input()
    describedby: string;

    @Output()
    clickHandler: EventEmitter<ClickHandlerResponse> = new EventEmitter<ClickHandlerResponse>();

    public formControls = {};

    public isOptionSelected(id: string): boolean {
        // if a selectedOptionId was passed in from a parent component,
        // such as in the case of a multiple choice element, compare that
        // to the id of the option.
        if (this.selectedOptionId) {
            return this.selectedOptionId === id;
        }

        // no selectedOptionId was passed and there are no responses
        if (!this.responses) return false;

        const response = find(this.responses, ['id', id]);
        return response ? response.value === true : false;
    }

    public isOptionDisabled(id: string): boolean {
        return this.maxSelectionReached && !this.isOptionSelected(id);
    }

    public execute(option: IMultilingualDataOption) {
        if (option.type === OptionType.NONE) {
            this.handleNoneOptionTypeSelection();
        }

        this.emit({...(option as IMultilingualDataOption)}, this.getCurrentSelectionMode(option.id));
    }

    public emit(option: IMultilingualDataOption, mode: OptionModeEnum) {
        this.clickHandler.emit({option, mode});
    }

    private handleNoneOptionTypeSelection() {
        this.formControls = {};
    }

    private getCurrentSelectionMode(id: string): OptionModeEnum {
        return this.isOptionSelected(id) ? OptionModeEnum.deselect : OptionModeEnum.select;
    }
}
