<div class="r-button-wrapper" [class.block]="doNotExpand">
    <button
        class="r-button"
        [class.selected]="selected"
        [class.disabled]="disabled || submitting"
        [class.general]="!negative && !affirmative"
        [class.affirmative]="affirmative"
        [class.negative]="negative"
        [class.selection]="selection"
        [class.submitting]="submitting"
        [ngClass]="[
            classes ? classes.join(' ') : '',
            !selected && isBrowserMobile && isMultiSelectQuestion ? 'not-selected' : ''
        ]"
        [attr.data-e2e]="e2e ? e2e : null"
        [attr.aria-describedby]="describedby"
        (click)="handleClick()"
        type="{{ type }}"
    >
        <ng-content></ng-content>
    </button>

    <span class="subtext" *ngIf="subtext">{{ subtext }}</span>
</div>
